<template lang="pug">
include ../../../../configs/template.pug
div.row
  div(v-if="checkAccess('serviceRecordBookLine', 'preVerification', sailorDocument) || checkAccess('serviceRecordBookLine', 'editStatus', sailorDocument)").col-12
    +select('body.status_document', 'listStatuses', 'status', 'labelName')
    v-checkbox(v-if="body.status_document ===  STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.APPROVED" v-model="body.is_confirmed" :label="$t('isConfirmedExperience')")
  div(v-if="checkAccess('serviceRecordBookLine', 'preVerification', sailorDocument) && statuses.includes(body.status_document)").col-12
    //- VerificationRefusal(type="serviceRecordBookLine" ref="verificationRefusal")
    Reject(:statusDocument="body.status_document")
  div(v-if="!statuses.includes(body.status_document)").col-12.mt-3
    v-btn(
      @click="checkAccess('serviceRecordBookLine', 'preVerification', sailorDocument) || checkAccess('serviceRecordBookLine', 'editStatus', sailorDocument) ? saveNewStatus() : checkSave()"
      :loading="isLoading"
      color="success") {{ checkAccess('serviceRecordBookLine', 'preVerification', sailorDocument) || checkAccess('serviceRecordBookLine', 'editStatus', sailorDocument) ? $t('save') : $t('setVerify') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapGetters, mapState, mapActions } from 'vuex'
import VerificationRefusal from '@/components/atoms/VerificationRefusal/VerificationRefusal.vue'
import { STATUSES_DOCUMENT_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    VerificationRefusal,
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      body: {
        status_document: this.sailorDocument.status_document,
        is_confirmed: this.sailorDocument.is_confirmed
      },
      statuses: [
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.REJECTED,
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.IN_PROCESSING
      ],
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['statusChoose']),
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      userId: state => state.main.user.id
    }),
    listStatuses () {
      let statuses = this.statusChoose('LineInServiceRecord')
      if (checkAccess('admin')) return statuses.concat(this.statusChoose('BackOffice'))
      if (this.userId === 188) return statuses.filter(val => (val.id === 9) || (this.sailorDocument.status_document === 9 && val.id === 10))
      else return statuses
    }
  },
  methods: {
    ...mapActions(['updateRecordBookLineEntry']),

    checkSave () {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('continueVerification'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('setVerify')],
        dangerMode: true
      }).then((confirmation) => {
        if (confirmation) this.saveNewStatus()
      })
    },
    async saveNewStatus () {
      this.isLoading = true
      const { VERIFICATION, APPROVED } = STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE
      if (!checkAccess('serviceRecordBookLine', 'preVerification', this.sailorDocument) ||
        !checkAccess('verifierBackOfficeSecond')) this.body.status_document = VERIFICATION

      if (this.body.status_document !== APPROVED) delete this.body.is_confirmed

      const response = await this.updateRecordBookLineEntry({ ...this.$route.params, body: { ...this.body } })
      if (SUCCESS_CODE.includes(response?.code)) this.$notification.success('editInfoRecordBook')
      this.isLoading = false
    }
  }
}
</script>
